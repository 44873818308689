import React from 'react'

export default function SquatText () {
  return (
    <>
      <p>
        Barbell Squats, particularly the Back Squat is the single most direct method to increase our
        overall body strength. Coach Miller has authored custom Squat programs for hundreds of
        athletes. In total these athletes have recorded over 2 TONS worth of 1RM increases!
      </p>
      <p>
        After your purchase, Coach Miller will email you with a few questions, then you too will be
        on your way to increased 1RM performance!
      </p>
    </>
  )
}
