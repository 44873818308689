import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SquatText from '../components/squat-program-text'

import './miller-squat-program.scss'
import squatImg from '../images/Image_Squat_Program.png'
import dolarIcon from '../images/dollar_black_48x48.png'
import StripeButton from '../components/stripe-button'
import Flex from '../components/flex'

const SKU_SQUAT = 'sku_Ek6A0hc3X8CxKb'

export default function Squat () {
  return (
    <Layout>
      <SEO title='Miller Squat Program' />
      <div className='squat-background-body'>
        <div className='squat-head-mobile'>
          <h1 className='squat-pro-title-mobile '>Miller Squat Program</h1>
        </div>
        <div style={{ maxWidth: '90%', margin: 'auto' }}>
          <div className='squat-head'>
            <h1>Miller Squat Program</h1>
          </div>
          <Flex>
            <div className='squat-content-left-col'>
              <SquatText />
            </div>
            <div className='squat-content-rigth-col'>
              <img
                src={squatImg}
                alt='bar weight'
                height='334'
                width='300'
                style={{ maxWidth: '100%' }}
                className='squat-image-mobile-hide'
              />
            </div>
          </Flex>
          <Flex mobileReverse style={{ alignItems: 'center' }}>
            <div className='squat-content-left-col'>
              <div className='btn-buynow-container'>
                <StripeButton
                  skus={[SKU_SQUAT]}
                  successUrl='/thank-you-squat'
                  className='squat-program-btn'
                >
                  BUY NOW
                </StripeButton>
              </div>
            </div>
            <div className='squat-price-container'>
              <div style={{ marginRight: '1em' }}>Squat Program</div>
              <img
                src={dolarIcon}
                alt='dolar icon'
                height='48'
                width='48'
                style={{ height: '1.5em', width: '1.5em', marginRight: '1px' }}
              />
              <div style={{ fontSize: '2em', fontWeight: 'bold', marginRight: '4px' }}>39</div>
            </div>
          </Flex>
        </div>
        <div style={{ height: '64px' }} />
      </div>
    </Layout>
  )
}
